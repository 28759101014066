export const cars = {
    Acura: [
        { label: "CL", value: "cl" },
        { label: "CSX", value: "csx" },
        { label: "ILX", value: "ilx" },
        { label: "Integra", value: "integra" },
        { label: "Legend", value: "legend" },
        { label: "MDX", value: "mdx" },
        { label: "RDX", value: "rdx" },
        { label: "RL", value: "rl" },
        { label: "RSX", value: "rsx" },
        { label: "SLX", value: "slx" },
        { label: "TL", value: "tl" },
        { label: "TLX", value: "tlx" },
        { label: "TSX", value: "tsx" },
        { label: "Vigor", value: "vigor" },
        { label: "ZDX", value: "zdx" },
    ],
    Adler: [
        { label: "Diplomat", value: "diplomat" },
        { label: "Standard", value: "standard" },
        { label: "Trumpf Junior", value: "trumpf junior" },
        { label: "Trumpf Karman", value: "trumpf karman" },
    ],
    Adria: [
        { label: "Active", value: "active" },
        { label: "Compact", value: "compact" },
        { label: "Coral", value: "coral" },
        { label: "Matrix", value: "matrix" },
        { label: "Sonic", value: "sonic" },
        { label: "Twin", value: "twin" },
    ],
    "Alfa Romeo": [
        { label: "145", value: "145" },
        { label: "146", value: "146" },
        { label: "147", value: "147" },
        { label: "155", value: "155" },
        { label: "156", value: "156" },
        { label: "159", value: "159" },
        { label: "164", value: "164" },
        { label: "166", value: "166" },
        { label: "33", value: "33" },
        { label: "75", value: "75" },
        { label: "90", value: "90" },
        { label: "Alfetta", value: "alfetta" },
        { label: "Brera", value: "brera" },
        { label: "Giulia", value: "giulia" },
        { label: "Giulietta", value: "giulietta" },
        { label: "GT", value: "gt" },
        { label: "GTV", value: "gtv" },
        { label: "MiTo", value: "mito" },
        { label: "Spider", value: "spider" },
    ],
    Alpina: [
        { label: "B3", value: "b3" },
        { label: "B4", value: "b4" },
        { label: "B5", value: "b5" },
        { label: "B6", value: "b6" },
        { label: "B7", value: "b7" },
        { label: "D3", value: "d3" },
        { label: "D4", value: "d4" },
        { label: "D5", value: "d5" },
        { label: "XD3", value: "xd3" },
    ],
    Aro: [
        { label: "240", value: "240" },
        { label: "243", value: "243" },
        { label: "244", value: "244" },
        { label: "246", value: "246" },
        { label: "266", value: "266" },
        { label: "323", value: "323" },
        { label: "324", value: "324" },
        { label: "328", value: "328" },
        { label: "330", value: "330" },
    ],
    Asia: [
        { label: "Rocsta", value: "rocsta" },
        { label: "Towner", value: "towner" },
    ],
    "Aston Martin": [
        { label: "DB9", value: "db9" },
        { label: "V12 Vantage", value: "v12 vantage" },
        { label: "V8 Vantage", value: "v8 vantage" },
    ],
    Audi: [
        { label: "100", value: "100" },
        { label: "200", value: "200" },
        { label: "50", value: "50" },
        { label: "80", value: "80" },
        { label: "90", value: "90" },
        { label: "A1", value: "a1" },
        { label: "A2", value: "a2" },
        { label: "A3", value: "a3" },
        { label: "A4", value: "a4" },
        { label: "A4 Allroad", value: "a4 allroad" },
        { label: "A5", value: "a5" },
        { label: "A6", value: "a6" },
        { label: "A6 Allroad", value: "a6 allroad" },
        { label: "A7", value: "a7" },
        { label: "A8", value: "a8" },
        { label: "Cabriolet", value: "cabriolet" },
        { label: "Coupe", value: "coupe" },
        { label: "e-tron", value: "e-tron" },
        { label: "e-tron Sportback", value: "e-tron sportback" },
        { label: "Q2", value: "q2" },
        { label: "Q3", value: "q3" },
        { label: "Q3 Sportback", value: "q3 sportback" },
        { label: "Q5", value: "q5" },
        { label: "Q7", value: "q7" },
        { label: "Q8", value: "q8" },
        { label: "R8", value: "r8" },
        { label: "RS Q3", value: "rs q3" },
        { label: "RS Q8", value: "rs q8" },
        { label: "RS2", value: "rs2" },
        { label: "RS3", value: "rs3" },
        { label: "RS4", value: "rs4" },
        { label: "RS5", value: "rs5" },
        { label: "RS6", value: "rs6" },
        { label: "RS7", value: "rs7" },
        { label: "S3", value: "s3" },
        { label: "S4", value: "s4" },
        { label: "S5", value: "s5" },
        { label: "S6", value: "s6" },
        { label: "S7", value: "s7" },
        { label: "S8", value: "s8" },
        { label: "SQ5", value: "sq5" },
        { label: "SQ7", value: "sq7" },
        { label: "SQ8", value: "sq8" },
        { label: "TT", value: "tt" },
        { label: "TT RS", value: "tt rs" },
        { label: "TTS", value: "tts" },
        { label: "V8", value: "v8" },
    ],
    Austin: [
        { label: "Allegro", value: "allegro" },
        { label: "Mini Metro", value: "mini metro" },
        { label: "Princess", value: "princess" },
    ],
    Avia: [
        { label: "A15", value: "a15" },
        { label: "A21", value: "a21" },
        { label: "A31", value: "a31" },
        { label: "A75", value: "a75" },
        { label: "D-Line", value: "d-line" },
        { label: "Ikarus 553", value: "ikarus 553" },
        { label: "S430", value: "s430" },
    ],
    Barkas: [{ label: "B1000", value: "b1000" }],
    BAW: [{ label: "Fenix", value: "fenix" }],
    Bentley: [
        { label: "Arnage", value: "arnage" },
        { label: "Azure", value: "azure" },
        { label: "Bentayga", value: "bentayga" },
        { label: "Brooklands", value: "brooklands" },
        { label: "Continental", value: "continental" },
        { label: "Continental GT", value: "continental gt" },
        { label: "Flying Spur", value: "flying spur" },
        { label: "Mulsanne", value: "mulsanne" },
        { label: "S3", value: "s3" },
        { label: "Turbo R", value: "turbo r" },
    ],
    Bertone: [{ label: "Freeclimber", value: "freeclimber" }],
    BMW: [
        { label: "Series 1", value: "series 1" },
        { label: "Series 2", value: "series 2" },
        { label: "Series 3", value: "series 3" },
        { label: "Series 4", value: "series 4" },
        { label: "Series 5", value: "series 5" },
        { label: "Series 6", value: "series 6" },
        { label: "Series 7", value: "series 7" },
        { label: "Series 8", value: "series 8" },
        { label: "Series M", value: "series m" },
        { label: "Series X", value: "series x" },
        { label: "Series Z", value: "series z" },
        { label: "Series i", value: "series i" },
        { label: "i3", value: "i3" },
        { label: "i8", value: "i8" },
        { label: "M2", value: "m2" },
        { label: "M3", value: "m3" },
        { label: "M4", value: "m4" },
        { label: "M5", value: "m5" },
        { label: "M6", value: "m6" },
        { label: "X1", value: "x1" },
        { label: "X2", value: "x2" },
        { label: "X3", value: "x3" },
        { label: "X3 M", value: "x3 m" },
        { label: "X4", value: "x4" },
        { label: "X4 M", value: "x4 m" },
        { label: "X5", value: "x5" },
        { label: "X5 M", value: "x5 m" },
        { label: "X6", value: "x6" },
        { label: "X6 M", value: "x6 m" },
        { label: "X7", value: "x7" },
        { label: "Z3", value: "z3" },
        { label: "Z4", value: "z4" },
        { label: "Z8", value: "z8" },
        { label: "840", value: "840" },
        { label: "850", value: "850" },
        { label: "Grand Coupe", value: "grand coupe" },
        { label: "Grand Turismo", value: "grand turismo" },
    ],
    Bremach: [{ label: "T-Rex", value: "t-rex" }],
    Brilliance: [
        { label: "BC3", value: "bc3" },
        { label: "BS2", value: "bs2" },
        { label: "BS4", value: "bs4" },
        { label: "BS6", value: "bs6" },
        { label: "H53", value: "h53" },
        { label: "V5", value: "v5" },
    ],
    Bugatti: [
        { label: "Chiron", value: "chiron" },
        { label: "Veyron", value: "veyron" },
    ],
    Buick: [
        { label: "Apollo", value: "apollo" },
        { label: "Century", value: "century" },
        { label: "Electra Wagon", value: "electra wagon" },
        { label: "Enclave", value: "enclave" },
        { label: "Encore", value: "encore" },
        { label: "GL8", value: "gl8" },
        { label: "LaCrosse", value: "lacrosse" },
        { label: "Le Sabre", value: "le sabre" },
        { label: "Lucerne", value: "lucerne" },
        { label: "Park Avenue", value: "park avenue" },
        { label: "Reatta", value: "reatta" },
        { label: "Regal", value: "regal" },
        { label: "Rendez Vous", value: "rendez vous" },
        { label: "Riviera", value: "riviera" },
        { label: "Roadmaster", value: "roadmaster" },
        { label: "Skylark", value: "skylark" },
    ],
    BYD: [
        { label: "E6", value: "e6" },
        { label: "F0", value: "f0" },
        { label: "F3", value: "f3" },
        { label: "F6", value: "f6" },
        { label: "Flyer", value: "flyer" },
        { label: "G3", value: "g3" },
        { label: "G6", value: "g6" },
        { label: "L3", value: "l3" },
        { label: "M6", value: "m6" },
        { label: "S6", value: "s6" },
    ],
    Cadillac: [
        { label: "Allante", value: "allante" },
        { label: "ATS", value: "ats" },
        { label: "BLS", value: "bls" },
        { label: "Brogham", value: "brogham" },
        { label: "Catera", value: "catera" },
        { label: "Cimarron", value: "cimarron" },
        { label: "CT6", value: "ct6" },
        { label: "CTS", value: "cts" },
        { label: "De Ville", value: "de ville" },
        { label: "DTS", value: "dts" },
        { label: "Eldorado", value: "eldorado" },
        { label: "ELR", value: "elr" },
        { label: "Escalade", value: "escalade" },
        { label: "Evoq", value: "evoq" },
        { label: "Flitwood", value: "flitwood" },
        { label: "GLS", value: "gls" },
        { label: "Seville", value: "seville" },
        { label: "SRX", value: "srx" },
        { label: "STS", value: "sts" },
        { label: "Superior", value: "superior" },
        { label: "Vizon", value: "vizon" },
        { label: "XLR", value: "xlr" },
        { label: "XT5", value: "xt5" },
    ],
    Changan: [
        { label: "CS35", value: "cs35" },
        { label: "Eado", value: "eado" },
        { label: "Raeton", value: "raeton" },
    ],
    Chery: [
        { label: "A1", value: "a1" },
        { label: "A13", value: "a13" },
        { label: "A5", value: "a5" },
        { label: "Amulet", value: "amulet" },
        { label: "Arrizo", value: "arrizo" },
        { label: "Bonus", value: "bonus" },
        { label: "Cowin", value: "cowin" },
        { label: "Cross Eastar", value: "cross eastar" },
        { label: "Fora", value: "fora" },
        { label: "Indis", value: "indis" },
        { label: "Kimo", value: "kimo" },
        { label: "M11", value: "m11" },
        { label: "Oriental Son", value: "oriental son" },
        { label: "QQ", value: "qq" },
        { label: "QQ6", value: "qq6" },
        { label: "Tiggo", value: "tiggo" },
        { label: "V5", value: "v5" },
        { label: "Very", value: "very" },
    ],
    Chevrolet: [
        { label: "Alero", value: "alero" },
        { label: "Astro Van", value: "astro van" },
        { label: "Avalanche", value: "avalanche" },
        { label: "Aveo", value: "aveo" },
        { label: "Beretta", value: "beretta" },
        { label: "Blazer", value: "blazer" },
        { label: "Bolt", value: "bolt" },
        { label: "C1500", value: "c1500" },
        { label: "Camaro", value: "camaro" },
        { label: "Caprice", value: "caprice" },
        { label: "Captiva", value: "captiva" },
        { label: "Cavalier", value: "cavalier" },
        { label: "Celebrity", value: "celebrity" },
        { label: "Chevy Van", value: "chevy van" },
        { label: "Cobalt", value: "cobalt" },
        { label: "Colorado", value: "colorado" },
        { label: "Corsica", value: "corsica" },
        { label: "Corvette", value: "corvette" },
        { label: "Cruze", value: "cruze" },
        { label: "CSV CR8", value: "csv cr8" },
        { label: "Epica", value: "epica" },
        { label: "Equinox", value: "equinox" },
        { label: "Evanda", value: "evanda" },
        { label: "Express", value: "express" },
        { label: "Fleetmaster", value: "fleetmaster" },
        { label: "Geo Storm", value: "geo storm" },
        { label: "HHR", value: "hhr" },
        { label: "Impala", value: "impala" },
        { label: "Kalos", value: "kalos" },
        { label: "Lacetti", value: "lacetti" },
        { label: "Lanos", value: "lanos" },
        { label: "Lumina", value: "lumina" },
        { label: "Malibu", value: "malibu" },
        { label: "Matiz", value: "matiz" },
        { label: "Metro", value: "metro" },
        { label: "Monte Carlo", value: "monte carlo" },
        { label: "Niva", value: "niva" },
        { label: "Nova", value: "nova" },
        { label: "Nubira", value: "nubira" },
        { label: "Optra", value: "optra" },
        { label: "Orlando", value: "orlando" },
        { label: "Prizm", value: "prizm" },
        { label: "Rezzo", value: "rezzo" },
        { label: "S-10", value: "s-10" },
        { label: "Shevi-Van", value: "shevi-van" },
        { label: "Silver", value: "silver" },
        { label: "Silverado", value: "silverado" },
        { label: "Sonic", value: "sonic" },
        { label: "Spark", value: "spark" },
        { label: "Spectrum", value: "spectrum" },
        { label: "Sprint", value: "sprint" },
        { label: "SSR", value: "ssr" },
        { label: "Suburban", value: "suburban" },
        { label: "Tacuma", value: "tacuma" },
        { label: "Tahoe", value: "tahoe" },
        { label: "Tracker", value: "tracker" },
        { label: "Trail Blazer", value: "trail blazer" },
        { label: "Trans Sport", value: "trans sport" },
        { label: "Traverse", value: "traverse" },
        { label: "Trax", value: "trax" },
        { label: "Uplander", value: "uplander" },
        { label: "Van", value: "van" },
        { label: "Venture", value: "venture" },
        { label: "Volt", value: "volt" },
        { label: "Yukon", value: "yukon" },
        { label: "Zafira", value: "zafira" },
    ],
    Chrysler: [
        { label: "180", value: "180" },
        { label: "200", value: "200" },
        { label: "300", value: "300" },
        { label: "Aspen", value: "aspen" },
        { label: "Caravan", value: "caravan" },
        { label: "Cirrus", value: "cirrus" },
        { label: "Concorde", value: "concorde" },
        { label: "Crossfire", value: "crossfire" },
        { label: "Daytona", value: "daytona" },
        { label: "Grand Voyager", value: "grand voyager" },
        { label: "GTS", value: "gts" },
        { label: "Horizon", value: "horizon" },
        { label: "Imperial", value: "imperial" },
        { label: "Intrepid", value: "intrepid" },
        { label: "Le Baron", value: "le baron" },
        { label: "LHS", value: "lhs" },
        { label: "Neon", value: "neon" },
        { label: "New Yorker", value: "new yorker" },
        { label: "Pacifica", value: "pacifica" },
        { label: "Prowler", value: "prowler" },
        { label: "PT Cruiser", value: "pt cruiser" },
        { label: "Saratoga", value: "saratoga" },
        { label: "Sebring", value: "sebring" },
        { label: "Shadow", value: "shadow" },
        { label: "Stratus", value: "stratus" },
        { label: "Town&Country", value: "town&country" },
        { label: "Viper", value: "viper" },
        { label: "Vision", value: "vision" },
        { label: "Voyager", value: "voyager" },
    ],
    Citroen: [
        { label: "2 CV", value: "2 cv" },
        { label: "AX", value: "ax" },
        { label: "Berlingo", value: "berlingo" },
        { label: "BX", value: "bx" },
        { label: "C-Crosser", value: "c-crosser" },
        { label: "C-ELYSÉE", value: "c-elysée" },
        { label: "C1", value: "c1" },
        { label: "C15", value: "c15" },
        { label: "C2", value: "c2" },
        { label: "C25", value: "c25" },
        { label: "C3", value: "c3" },
        { label: "C3 Picasso", value: "c3 picasso" },
        { label: "C4", value: "c4" },
        { label: "C4 AirCross", value: "c4 aircross" },
        { label: "C4 Cactus", value: "c4 cactus" },
        { label: "C4 Grand Picasso", value: "c4 grand picasso" },
        { label: "C4 Picasso", value: "c4 picasso" },
        { label: "C5", value: "c5" },
        { label: "C6", value: "c6" },
        { label: "C8", value: "c8" },
        { label: "CX", value: "cx" },
        { label: "DS3", value: "ds3" },
        { label: "DS4", value: "ds4" },
        { label: "DS5", value: "ds5" },
        { label: "Evasion", value: "evasion" },
        { label: "GSA", value: "gsa" },
        { label: "Jumper", value: "jumper" },
        { label: "Jumpy", value: "jumpy" },
        { label: "Nemo", value: "nemo" },
        { label: "Saxo", value: "saxo" },
        { label: "SM", value: "sm" },
        { label: "SpaceTourer", value: "spacetourer" },
        { label: "Visa", value: "visa" },
        { label: "Xantia", value: "xantia" },
        { label: "XM", value: "xm" },
        { label: "Xsara", value: "xsara" },
        { label: "Xsara Picasso", value: "xsara picasso" },
        { label: "ZX", value: "zx" },
    ],
    Dacia: [
        { label: "1300", value: "1300" },
        { label: "1310", value: "1310" },
        { label: "Dokker", value: "dokker" },
        { label: "Duster", value: "duster" },
        { label: "Lodgy", value: "lodgy" },
        { label: "Logan", value: "logan" },
        { label: "Pick Up", value: "pick up" },
        { label: "Sandero", value: "sandero" },
    ],
    Daewoo: [
        { label: "Damas", value: "damas" },
        { label: "Espero", value: "espero" },
        { label: "Evanda", value: "evanda" },
        { label: "Gentra", value: "gentra" },
        { label: "Kalos", value: "kalos" },
        { label: "Korando", value: "korando" },
        { label: "Lacetti", value: "lacetti" },
        { label: "Lanos", value: "lanos" },
        { label: "Leganza", value: "leganza" },
        { label: "Magnus", value: "magnus" },
        { label: "Matiz", value: "matiz" },
        { label: "Musso", value: "musso" },
        { label: "Nexia", value: "nexia" },
        { label: "Nubira", value: "nubira" },
        { label: "Prince", value: "prince" },
        { label: "Racer", value: "racer" },
        { label: "Rezzo", value: "rezzo" },
        { label: "Sens", value: "sens" },
        { label: "Tacuma", value: "tacuma" },
        { label: "Tico", value: "tico" },
    ],
    DAF: [
        { label: "200", value: "200" },
        { label: "400", value: "400" },
        { label: "46", value: "46" },
        { label: "66", value: "66" },
    ],
    Daihatsu: [
        { label: "Applause", value: "applause" },
        { label: "Charade", value: "charade" },
        { label: "Charmant", value: "charmant" },
        { label: "Copen", value: "copen" },
        { label: "Cuore", value: "cuore" },
        { label: "Feroza/Sportrak", value: "feroza/sportrak" },
        { label: "Freeclimber", value: "freeclimber" },
        { label: "Gran Move", value: "gran move" },
        { label: "Hijet", value: "hijet" },
        { label: "Materia", value: "materia" },
        { label: "Move", value: "move" },
        { label: "Rocky/Fourtrak", value: "rocky/fourtrak" },
        { label: "Sirion", value: "sirion" },
        { label: "Taft", value: "taft" },
        { label: "Terios", value: "terios" },
        { label: "Trevis", value: "trevis" },
        { label: "Valera", value: "valera" },
        { label: "YRV", value: "yrv" },
    ],
    Datsun: [
        { label: "1000", value: "1000" },
        { label: "100A", value: "100a" },
        { label: "Bluebird 310", value: "bluebird 310" },
        { label: "Go", value: "go" },
        { label: "Go+", value: "go+" },
        { label: "mi-DO", value: "mi-do" },
        { label: "on-DO", value: "on-do" },
        { label: "Sunny", value: "sunny" },
        { label: "Sunny B10", value: "sunny b10" },
    ],
    DeLorean: [{ label: "DMC-12", value: "dmc-12" }],
    Derways: [
        { label: "Antelope", value: "antelope" },
        { label: "Aurora", value: "aurora" },
        { label: "Cowboy", value: "cowboy" },
        { label: "Land Crown", value: "land crown" },
        { label: "Plutus", value: "plutus" },
        { label: "Saladin", value: "saladin" },
        { label: "Shuttle", value: "shuttle" },
    ],
    Dodge: [
        { label: "1500", value: "1500" },
        { label: "2500", value: "2500" },
        { label: "3500", value: "3500" },
        { label: "Aspen", value: "aspen" },
        { label: "Avenger", value: "avenger" },
        { label: "Caliber", value: "caliber" },
        { label: "Caravan", value: "caravan" },
        { label: "Challenger", value: "challenger" },
        { label: "Charger", value: "charger" },
        { label: "Colt", value: "colt" },
        { label: "D150", value: "d150" },
        { label: "D250", value: "d250" },
        { label: "D350", value: "d350" },
        { label: "Dakota", value: "dakota" },
        { label: "Dart", value: "dart" },
        { label: "Daytona", value: "daytona" },
        { label: "Durango", value: "durango" },
        { label: "Durango Hybrid", value: "durango hybrid" },
        { label: "Dynasty", value: "dynasty" },
        { label: "Grand Caravan", value: "grand caravan" },
        { label: "Intrepid", value: "intrepid" },
        { label: "Journey", value: "journey" },
        { label: "Magnum", value: "magnum" },
        { label: "Monaco", value: "monaco" },
        { label: "Neon", value: "neon" },
        { label: "Nitro", value: "nitro" },
        { label: "Omni", value: "omni" },
        { label: "Ram", value: "ram" },
        { label: "Roadtrek", value: "roadtrek" },
        { label: "Shadow", value: "shadow" },
        { label: "Spirit", value: "spirit" },
        { label: "Sprinter", value: "sprinter" },
        { label: "Stealth", value: "stealth" },
        { label: "Stratus", value: "stratus" },
        { label: "Van", value: "van" },
        { label: "Viper", value: "viper" },
        { label: "W150", value: "w150" },
        { label: "W250", value: "w250" },
        { label: "W350", value: "w350" },
    ],
    Dongfeng: [
        { label: "AX7", value: "ax7" },
        { label: "H30", value: "h30" },
        { label: "S30", value: "s30" },
    ],
    Eagle: [{ label: "Premier", value: "premier" }],
    Excalibur: [{ label: "Phantom", value: "phantom" }],
    "Factory Five": [
        { label: "'33 Hot Rod", value: "'33 hot rod" },
        { label: "818", value: "818" },
        { label: "GTM Supercar", value: "gtm supercar" },
        { label: "MK4 Roadster", value: "mk4 roadster" },
        { label: "Type 65 Coupe", value: "type 65 coupe" },
    ],
    FAW: [
        { label: "1041", value: "1041" },
        { label: "6371", value: "6371" },
        { label: "Besturn B", value: "besturn b" },
        { label: "Jinn", value: "jinn" },
        { label: "Oley", value: "oley" },
        { label: "Vela", value: "vela" },
        { label: "Vita", value: "vita" },
        { label: "Vita Sedan", value: "vita sedan" },
        { label: "Vizi V5", value: "vizi v5" },
        { label: "Xiali N5", value: "xiali n5" },
    ],
    Ferrari: [
        { label: "150 GTO", value: "150 gto" },
        { label: "250 GTO", value: "250 gto" },
        { label: "328", value: "328" },
        { label: "330", value: "330" },
        { label: "360 Modena", value: "360 modena" },
        { label: "365", value: "365" },
        { label: "456 GT/GTA", value: "456 gt/gta" },
        { label: "512 M", value: "512 m" },
        { label: "550 Maranello", value: "550 maranello" },
        { label: "575 Maranello", value: "575 maranello" },
        { label: "626", value: "626" },
        { label: "California", value: "california" },
        { label: "F355 Berlinetta", value: "f355 berlinetta" },
        { label: "F40", value: "f40" },
        { label: "F50", value: "f50" },
        { label: "Mondial", value: "mondial" },
    ],
    Fiat: [
        { label: "124", value: "124" },
        { label: "124 Spider", value: "124 spider" },
        { label: "125", value: "125" },
        { label: "126", value: "126" },
        { label: "127", value: "127" },
        { label: "128", value: "128" },
        { label: "130", value: "130" },
        { label: "131", value: "131" },
        { label: "238", value: "238" },
        { label: "500", value: "500" },
        { label: "500L", value: "500l" },
        { label: "500X", value: "500x" },
        { label: "900t", value: "900t" },
        { label: "Albea", value: "albea" },
        { label: "Argenta", value: "argenta" },
        { label: "Barchetta", value: "barchetta" },
        { label: "Brava", value: "brava" },
        { label: "Bravo", value: "bravo" },
        { label: "Cinquecento", value: "cinquecento" },
        { label: "Coupe", value: "coupe" },
        { label: "Croma", value: "croma" },
        { label: "Dino", value: "dino" },
        { label: "Doblo", value: "doblo" },
        { label: "Ducato", value: "ducato" },
        { label: "Fiorino", value: "fiorino" },
        { label: "Freemont", value: "freemont" },
        { label: "Grande Punto", value: "grande punto" },
        { label: "Idea", value: "idea" },
        { label: "Lancia", value: "lancia" },
        { label: "Linea", value: "linea" },
        { label: "Marea", value: "marea" },
        { label: "Marengo", value: "marengo" },
        { label: "Multipla", value: "multipla" },
        { label: "Palio", value: "palio" },
        { label: "Panda", value: "panda" },
        { label: "Punto", value: "punto" },
        { label: "Qubo", value: "qubo" },
        { label: "Regata", value: "regata" },
        { label: "Ritmo", value: "ritmo" },
        { label: "Scudo", value: "scudo" },
        { label: "Sedici", value: "sedici" },
        { label: "Seicento", value: "seicento" },
        { label: "Siena", value: "siena" },
        { label: "Spider Europa", value: "spider europa" },
        { label: "Stilo", value: "stilo" },
        { label: "Strada", value: "strada" },
        { label: "Talento", value: "talento" },
        { label: "Tempra", value: "tempra" },
        { label: "Tipo", value: "tipo" },
        { label: "Ulyss", value: "ulyss" },
        { label: "Uno", value: "uno" },
        { label: "X 1/9", value: "x 1/9" },
    ],
    Fisker: [{ label: "Karma", value: "karma" }],
    Ford: [
        { label: "Aerostar", value: "aerostar" },
        { label: "B-Max", value: "b-max" },
        { label: "Bronco", value: "bronco" },
        { label: "C-Max", value: "c-max" },
        { label: "Capri", value: "capri" },
        { label: "Consul", value: "consul" },
        { label: "Contour", value: "contour" },
        { label: "Cougar", value: "cougar" },
        { label: "Courier", value: "courier" },
        { label: "Crown Victoria", value: "crown victoria" },
        { label: "E450", value: "e450" },
        { label: "Econoline", value: "econoline" },
        { label: "Econovan", value: "econovan" },
        { label: "EcoSport", value: "ecosport" },
        { label: "Edge", value: "edge" },
        { label: "Escape", value: "escape" },
        { label: "Escort", value: "escort" },
        { label: "Excursion", value: "excursion" },
        { label: "Expedition", value: "expedition" },
        { label: "Explorer", value: "explorer" },
        { label: "Express", value: "express" },
        { label: "F150", value: "f150" },
        { label: "F250", value: "f250" },
        { label: "F350", value: "f350" },
        { label: "Fairlane", value: "fairlane" },
        { label: "Fairmont", value: "fairmont" },
        { label: "Falcon", value: "falcon" },
        { label: "Fiesta", value: "fiesta" },
        { label: "Five Hundred", value: "five hundred" },
        { label: "Flex", value: "flex" },
        { label: "Focus", value: "focus" },
        { label: "Freestar", value: "freestar" },
        { label: "Freestyle", value: "freestyle" },
        { label: "Fusion", value: "fusion" },
        { label: "Galaxy", value: "galaxy" },
        { label: "Granada", value: "granada" },
        { label: "Grand C-Max", value: "grand c-max" },
        { label: "GT", value: "gt" },
        { label: "Ka", value: "ka" },
        { label: "Kuga", value: "kuga" },
        { label: "Maverick", value: "maverick" },
        { label: "Mercury", value: "mercury" },
        { label: "Model 40A", value: "model 40a" },
        { label: "Mondeo", value: "mondeo" },
        { label: "Mustang", value: "mustang" },
        { label: "Orion", value: "orion" },
        { label: "Probe", value: "probe" },
        { label: "Puma", value: "puma" },
        { label: "Ranger", value: "ranger" },
        { label: "S-Max", value: "s-max" },
        { label: "Scorpio", value: "scorpio" },
        { label: "Sierra", value: "sierra" },
        { label: "Sportka", value: "sportka" },
        { label: "Streetka", value: "streetka" },
        { label: "Taunus", value: "taunus" },
        { label: "Taurus", value: "taurus" },
        { label: "Tempo", value: "tempo" },
        { label: "Thunderbird", value: "thunderbird" },
        { label: "Tourneo", value: "tourneo" },
        { label: "Tourneo Custom", value: "tourneo custom" },
        { label: "Transit", value: "transit" },
        { label: "V8", value: "v8" },
        { label: "Windstar", value: "windstar" },
    ],
    Foton: [
        { label: "Aumark", value: "aumark" },
        { label: "Forland", value: "forland" },
        { label: "Ollin", value: "ollin" },
        { label: "Saga", value: "saga" },
        { label: "Sup", value: "sup" },
        { label: "View", value: "view" },
    ],
    FSO: [
        { label: "Polonez", value: "polonez" },
        { label: "Warszawa", value: "warszawa" },
    ],
    Geely: [
        { label: "Atlas", value: "atlas" },
        { label: "CK", value: "ck" },
        { label: "EC7", value: "ec7" },
        { label: "Emgrand", value: "emgrand" },
        { label: "Emgrand 7", value: "emgrand 7" },
        { label: "Emgrand 8", value: "emgrand 8" },
        { label: "EX7", value: "ex7" },
        { label: "FC", value: "fc" },
        { label: "GC6", value: "gc6" },
        { label: "LC", value: "lc" },
        { label: "MK", value: "mk" },
        { label: "SC7", value: "sc7" },
    ],
    Genesis: [
        { label: "G70", value: "g70" },
        { label: "G80", value: "g80" },
        { label: "G90", value: "g90" },
    ],
    GMC: [
        { label: "Acadia", value: "acadia" },
        { label: "Canyon", value: "canyon" },
        { label: "Envoy", value: "envoy" },
        { label: "Jimmy", value: "jimmy" },
        { label: "Safari", value: "safari" },
        { label: "Savana", value: "savana" },
        { label: "Sierra", value: "sierra" },
        { label: "Sonoma", value: "sonoma" },
        { label: "Syclone", value: "syclone" },
        { label: "Terrain", value: "terrain" },
        { label: "Typhoon", value: "typhoon" },
        { label: "Vandura", value: "vandura" },
        { label: "Yukon", value: "yukon" },
    ],
    "Great Wall": [
        { label: "Coolbear", value: "coolbear" },
        { label: "Deer", value: "deer" },
        { label: "Florid", value: "florid" },
        { label: "H3", value: "h3" },
        { label: "H5", value: "h5" },
        { label: "H6", value: "h6" },
        { label: "Hover", value: "hover" },
        { label: "M4", value: "m4" },
        { label: "Pegasus", value: "pegasus" },
        { label: "Peri", value: "peri" },
        { label: "Safe", value: "safe" },
        { label: "Sailor", value: "sailor" },
        { label: "Sing", value: "sing" },
        { label: "Voleex C30", value: "voleex c30" },
        { label: "Wingle", value: "wingle" },
    ],
    Hafei: [
        { label: "Brio", value: "brio" },
        { label: "Lobo", value: "lobo" },
        { label: "Minyi", value: "minyi" },
        { label: "Princip", value: "princip" },
        { label: "Simbo", value: "simbo" },
    ],
    Haima: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "7", value: "7" },
        { label: "Family", value: "family" },
        { label: "Freema", value: "freema" },
        { label: "Fstar", value: "fstar" },
        { label: "Happin", value: "happin" },
        { label: "M3", value: "m3" },
        { label: "M5", value: "m5" },
        { label: "M8", value: "m8" },
        { label: "S5", value: "s5" },
        { label: "S7", value: "s7" },
    ],
    Hanjiang: [
        { label: "SFJ6350", value: "sfj6350" },
        { label: "SFJ6370", value: "sfj6370" },
    ],
    Hanomag: [{ label: "F", value: "f" }],
    Haval: [
        { label: "H2", value: "h2" },
        { label: "H6", value: "h6" },
        { label: "H8", value: "h8" },
        { label: "H9", value: "h9" },
    ],
    Honda: [
        { label: "Accord", value: "accord" },
        { label: "Aerodeck", value: "aerodeck" },
        { label: "Ballade", value: "ballade" },
        { label: "City", value: "city" },
        { label: "Civic", value: "civic" },
        { label: "Clarity", value: "clarity" },
        { label: "Concerto", value: "concerto" },
        { label: "CR-V", value: "cr-v" },
        { label: "CR-Z", value: "cr-z" },
        { label: "Crosstour", value: "crosstour" },
        { label: "CRX", value: "crx" },
        { label: "Element", value: "element" },
        { label: "Elysion", value: "elysion" },
        { label: "Fit", value: "fit" },
        { label: "FR-V", value: "fr-v" },
        { label: "HR-V", value: "hr-v" },
        { label: "Insight", value: "insight" },
        { label: "Integra", value: "integra" },
        { label: "Jazz", value: "jazz" },
        { label: "Legend", value: "legend" },
        { label: "Logo", value: "logo" },
        { label: "NSX", value: "nsx" },
        { label: "Odyssey", value: "odyssey" },
        { label: "Passport", value: "passport" },
        { label: "Pilot", value: "pilot" },
        { label: "Prelude", value: "prelude" },
        { label: "Ridgeline", value: "ridgeline" },
        { label: "S1000", value: "s1000" },
        { label: "S2000", value: "s2000" },
        { label: "Shuttle", value: "shuttle" },
        { label: "Stream", value: "stream" },
        { label: "Vigor", value: "vigor" },
    ],
    Hongxing: [{ label: "Noble", value: "noble" }],
    Huanghai: [
        { label: "Antelope", value: "antelope" },
        { label: "Aurora", value: "aurora" },
        { label: "Challenger", value: "challenger" },
        { label: "Landscape", value: "landscape" },
        { label: "Major", value: "major" },
        { label: "Plutus", value: "plutus" },
        { label: "Starex", value: "starex" },
    ],
    Hummer: [
        { label: "H1", value: "h1" },
        { label: "H2", value: "h2" },
        { label: "H3", value: "h3" },
    ],
    Hunta: [{ label: "Overcomer", value: "overcomer" }],
    Hyundai: [
        { label: "Accent", value: "accent" },
        { label: "Atos", value: "atos" },
        { label: "Avante", value: "avante" },
        { label: "Azera", value: "azera" },
        { label: "Centennial", value: "centennial" },
        { label: "Coupe", value: "coupe" },
        { label: "Creta", value: "creta" },
        { label: "Elantra", value: "elantra" },
        { label: "Equus", value: "equus" },
        { label: "Excel", value: "excel" },
        { label: "Galloper", value: "galloper" },
        { label: "Genesis", value: "genesis" },
        { label: "Getz", value: "getz" },
        { label: "Grand Starex", value: "grand starex" },
        { label: "Grandeur", value: "grandeur" },
        { label: "H 100", value: "h 100" },
        { label: "H 200", value: "h 200" },
        { label: "H-1", value: "h-1" },
        { label: "Hd", value: "hd" },
        { label: "i10", value: "i10" },
        { label: "i20", value: "i20" },
        { label: "i30", value: "i30" },
        { label: "i40", value: "i40" },
        { label: "i50", value: "i50" },
        { label: "Ioniq", value: "ioniq" },
        { label: "ix20", value: "ix20" },
        { label: "ix35", value: "ix35" },
        { label: "ix55", value: "ix55" },
        { label: "Lantra", value: "lantra" },
        { label: "Matrix", value: "matrix" },
        { label: "Maxcruz", value: "maxcruz" },
        { label: "Pony", value: "pony" },
        { label: "Porter", value: "porter" },
        { label: "S-Coupe", value: "s-coupe" },
        { label: "Santa Fe", value: "santa fe" },
        { label: "Santamo", value: "santamo" },
        { label: "Solaris", value: "solaris" },
        { label: "Sonata", value: "sonata" },
        { label: "Starex", value: "starex" },
        { label: "Terracan", value: "terracan" },
        { label: "Tiburon", value: "tiburon" },
        { label: "Trajet", value: "trajet" },
        { label: "Tucson", value: "tucson" },
        { label: "Veloster", value: "veloster" },
        { label: "Veracruz", value: "veracruz" },
        { label: "XG 25", value: "xg 25" },
        { label: "XG 30", value: "xg 30" },
        { label: "XG 350", value: "xg 350" },
    ],
    Infiniti: [
        { label: "EX", value: "ex" },
        { label: "FX", value: "fx" },
        { label: "G", value: "g" },
        { label: "I", value: "i" },
        { label: "JX", value: "jx" },
        { label: "M", value: "m" },
        { label: "Q", value: "q" },
        { label: "QX", value: "qx" },
    ],
    Intrall: [{ label: "Lublin", value: "lublin" }],
    "Iran Khodro": [{ label: "Samand", value: "samand" }],
    Isuzu: [
        { label: "Amigo", value: "amigo" },
        { label: "Aska", value: "aska" },
        { label: "Axiom", value: "axiom" },
        { label: "Campo", value: "campo" },
        { label: "D-Max", value: "d-max" },
        { label: "Gemini", value: "gemini" },
        { label: "Midi", value: "midi" },
        { label: "Pick Up", value: "pick up" },
        { label: "Rodeo", value: "rodeo" },
        { label: "Stylus", value: "stylus" },
        { label: "Trooper", value: "trooper" },
        { label: "Vehiсross", value: "vehiсross" },
    ],
    Iveco: [{ label: "Daily", value: "daily" }],
    JAC: [
        { label: "HFC", value: "hfc" },
        { label: "HK", value: "hk" },
        { label: "J2", value: "j2" },
        { label: "MPV", value: "mpv" },
        { label: "Refine", value: "refine" },
        { label: "Rein", value: "rein" },
        { label: "S5", value: "s5" },
        { label: "SRV", value: "srv" },
        { label: "Tojoy", value: "tojoy" },
    ],
    Jaguar: [
        { label: "Daimler", value: "daimler" },
        { label: "E-Pace", value: "e-pace" },
        { label: "E-Type", value: "e-type" },
        { label: "F-Pace", value: "f-pace" },
        { label: "F-Type", value: "f-type" },
        { label: "MK II", value: "mk ii" },
        { label: "S-Type", value: "s-type" },
        { label: "X-Type", value: "x-type" },
        { label: "XE", value: "xe" },
        { label: "XF", value: "xf" },
        { label: "XJ", value: "xj" },
        { label: "XJR", value: "xjr" },
        { label: "XK", value: "xk" },
        { label: "XKR", value: "xkr" },
    ],
    Jeep: [
        { label: "Cherokee", value: "cherokee" },
        { label: "CJ", value: "cj" },
        { label: "Comanche", value: "comanche" },
        { label: "Commander", value: "commander" },
        { label: "Compass", value: "compass" },
        { label: "Grand Cherokee", value: "grand cherokee" },
        { label: "Liberty", value: "liberty" },
        { label: "Patriot", value: "patriot" },
        { label: "Renegade", value: "renegade" },
        { label: "Wagoneer", value: "wagoneer" },
        { label: "Willys", value: "willys" },
        { label: "Wrangler", value: "wrangler" },
    ],
    Jiangling: [{ label: "Landwind", value: "landwind" }],
    Kia: [
        { label: "Avella", value: "avella" },
        { label: "Besta", value: "besta" },
        { label: "Cadenza", value: "cadenza" },
        { label: "Capital", value: "capital" },
        { label: "Carens", value: "carens" },
        { label: "Carnival", value: "carnival" },
        { label: "Cee'd", value: "cee'd" },
        { label: "Cerato", value: "cerato" },
        { label: "Clarus", value: "clarus" },
        { label: "Elan", value: "elan" },
        { label: "Forte", value: "forte" },
        { label: "Joice", value: "joice" },
        { label: "K2500", value: "k2500" },
        { label: "K2700", value: "k2700" },
        { label: "K3", value: "k3" },
        { label: "K5", value: "k5" },
        { label: "K7", value: "k7" },
        { label: "K9", value: "k9" },
        { label: "Leo", value: "leo" },
        { label: "Magentis", value: "magentis" },
        { label: "Mentor", value: "mentor" },
        { label: "Mini", value: "mini" },
        { label: "Mohave", value: "mohave" },
        { label: "Opirus", value: "opirus" },
        { label: "Optima", value: "optima" },
        { label: "Picanto", value: "picanto" },
        { label: "Pregio", value: "pregio" },
        { label: "Pride", value: "pride" },
        { label: "Pro Cee'd", value: "pro cee'd" },
        { label: "Quoris", value: "quoris" },
        { label: "Ray", value: "ray" },
        { label: "Retona", value: "retona" },
        { label: "Rio", value: "rio" },
        { label: "Roadster", value: "roadster" },
        { label: "Rocsta", value: "rocsta" },
        { label: "Sedona", value: "sedona" },
        { label: "Sephia", value: "sephia" },
        { label: "Shuma", value: "shuma" },
        { label: "Sorento", value: "sorento" },
        { label: "Soul", value: "soul" },
        { label: "Spectra", value: "spectra" },
        { label: "Sportage", value: "sportage" },
        { label: "Stinger", value: "stinger" },
        { label: "Venga", value: "venga" },
        { label: "X-Trek", value: "x-trek" },
    ],
    Koenigsegg: [
        { label: "Agera", value: "agera" },
        { label: "CC", value: "cc" },
        { label: "CC8S", value: "cc8s" },
        { label: "CCGT", value: "ccgt" },
        { label: "CCR", value: "ccr" },
        { label: "CCX", value: "ccx" },
        { label: "CCXR", value: "ccxr" },
        { label: "Quant", value: "quant" },
        { label: "Trevita", value: "trevita" },
    ],
    LADA: [
        { label: "110", value: "110" },
        { label: "111", value: "111" },
        { label: "112", value: "112" },
        { label: "4x4", value: "4x4" },
        { label: "Granta", value: "granta" },
        { label: "Kalina", value: "kalina" },
        { label: "Largus", value: "largus" },
        { label: "Priora", value: "priora" },
        { label: "Vesta", value: "vesta" },
        { label: "XRAY", value: "xray" },
        { label: "ВИС", value: "вис" },
        { label: "Лаура", value: "лаура" },
    ],
    Lamborghini: [
        { label: "Aventador", value: "aventador" },
        { label: "Countach", value: "countach" },
        { label: "Diablo", value: "diablo" },
        { label: "Gallardo", value: "gallardo" },
        { label: "Huracán", value: "huracán" },
        { label: "Murcielago", value: "murcielago" },
        { label: "Reventon", value: "reventon" },
    ],
    Lancia: [
        { label: "Beta", value: "beta" },
        { label: "Dedra", value: "dedra" },
        { label: "Delta", value: "delta" },
        { label: "Flaminia", value: "flaminia" },
        { label: "Fulvia", value: "fulvia" },
        { label: "Gamma", value: "gamma" },
        { label: "Kappa", value: "kappa" },
        { label: "Lybra", value: "lybra" },
        { label: "Musa", value: "musa" },
        { label: "Phedra", value: "phedra" },
        { label: "Prisma", value: "prisma" },
        { label: "Stratos", value: "stratos" },
        { label: "Thema", value: "thema" },
        { label: "Thesis", value: "thesis" },
        { label: "Voyager", value: "voyager" },
        { label: "Ypsilon", value: "ypsilon" },
        { label: "Zeta", value: "zeta" },
    ],
    "Land Rover": [
        { label: "Defender", value: "defender" },
        { label: "Discovery", value: "discovery" },
        { label: "Discovery Sport", value: "discovery sport" },
        { label: "Freelander", value: "freelander" },
        { label: "Range Rover", value: "range rover" },
        { label: "Range Rover Evoque", value: "range rover evoque" },
        { label: "Range Rover Sport", value: "range rover sport" },
    ],
    LDV: [
        { label: "Convoy", value: "convoy" },
        { label: "Maxus", value: "maxus" },
        { label: "Pilot", value: "pilot" },
    ],
    Lexus: [
        { label: "CT", value: "ct" },
        { label: "ES", value: "es" },
        { label: "GS", value: "gs" },
        { label: "GX", value: "gx" },
        { label: "HS", value: "hs" },
        { label: "IS", value: "is" },
        { label: "LC", value: "lc" },
        { label: "LS", value: "ls" },
        { label: "LX", value: "lx" },
        { label: "NX", value: "nx" },
        { label: "RC", value: "rc" },
        { label: "RX", value: "rx" },
        { label: "SC", value: "sc" },
        { label: "UX", value: "ux" },
    ],
    Lifan: [
        { label: "320", value: "320" },
        { label: "520", value: "520" },
        { label: "620", value: "620" },
        { label: "Breez", value: "breez" },
        { label: "Cebrium", value: "cebrium" },
        { label: "Celliya", value: "celliya" },
        { label: "Murman", value: "murman" },
        { label: "MyWay", value: "myway" },
        { label: "Smily", value: "smily" },
        { label: "Solano", value: "solano" },
        { label: "X50", value: "x50" },
        { label: "X60", value: "x60" },
        { label: "X70", value: "x70" },
    ],
    Lincoln: [
        { label: "Aviator", value: "aviator" },
        { label: "Blackwood", value: "blackwood" },
        { label: "Continental", value: "continental" },
        { label: "LS", value: "ls" },
        { label: "Mark LT", value: "mark lt" },
        { label: "Mark VII", value: "mark vii" },
        { label: "Mark VIII", value: "mark viii" },
        { label: "MKC", value: "mkc" },
        { label: "MKS", value: "mks" },
        { label: "MKT", value: "mkt" },
        { label: "MKX", value: "mkx" },
        { label: "MKZ", value: "mkz" },
        { label: "Navigator", value: "navigator" },
        { label: "Town Car", value: "town car" },
        { label: "Zephyr", value: "zephyr" },
    ],
    Lotus: [
        { label: "Elise", value: "elise" },
        { label: "Esprit", value: "esprit" },
        { label: "Europa", value: "europa" },
        { label: "Europa S", value: "europa s" },
        { label: "Europa SE", value: "europa se" },
        { label: "Evora", value: "evora" },
        { label: "Exige", value: "exige" },
    ],
    Mahindra: [
        { label: "Alturas G4", value: "alturas g4" },
        { label: "Bolero", value: "bolero" },
        { label: "Bolero Power Plus", value: "bolero power plus" },
        { label: "E Verito", value: "e verito" },
        { label: "e2oPlus", value: "e2oplus" },
        { label: "KUV100 NXT", value: "kuv100 nxt" },
        { label: "Marazzo", value: "marazzo" },
        { label: "NuvoSport", value: "nuvosport" },
        { label: "Pik-Up", value: "pik-up" },
        { label: "Scorpio", value: "scorpio" },
        { label: "Supro", value: "supro" },
        { label: "Thar", value: "thar" },
        { label: "TUV 300", value: "tuv 300" },
        { label: "TUV 300 Plus", value: "tuv 300 plus" },
        { label: "Verito", value: "verito" },
        { label: "Verito Vibe", value: "verito vibe" },
        { label: "XUV500", value: "xuv500" },
        { label: "Xylo", value: "xylo" },
    ],
    Maserati: [
        { label: "228", value: "228" },
        { label: "3200GT", value: "3200gt" },
        { label: "Coupe Cambiocorsa", value: "coupe cambiocorsa" },
        { label: "Ghibli", value: "ghibli" },
        { label: "GranTurismo", value: "granturismo" },
        { label: "Levante", value: "levante" },
        { label: "M138 Spyder", value: "m138 spyder" },
        { label: "Quattroporte", value: "quattroporte" },
    ],
    Maybach: [
        { label: "57", value: "57" },
        { label: "62", value: "62" },
    ],
    Mazda: [
        { label: "121", value: "121" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "323", value: "323" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "626", value: "626" },
        { label: "929", value: "929" },
        { label: "B1800", value: "b1800" },
        { label: "B2000", value: "b2000" },
        { label: "B2200", value: "b2200" },
        { label: "B2500", value: "b2500" },
        { label: "B2600", value: "b2600" },
        { label: "B3000", value: "b3000" },
        { label: "B3400", value: "b3400" },
        { label: "BT-50", value: "bt-50" },
        { label: "Capella", value: "capella" },
        { label: "CX-3", value: "cx-3" },
        { label: "CX-5", value: "cx-5" },
        { label: "CX-7", value: "cx-7" },
        { label: "CX-9", value: "cx-9" },
        { label: "Demio", value: "demio" },
        { label: "E2200", value: "e2200" },
        { label: "Luce", value: "luce" },
        { label: "Millenia", value: "millenia" },
        { label: "MPV", value: "mpv" },
        { label: "MX-3", value: "mx-3" },
        { label: "MX-5", value: "mx-5" },
        { label: "MX-6", value: "mx-6" },
        { label: "Navajo", value: "navajo" },
        { label: "Premacy", value: "premacy" },
        { label: "Protege", value: "protege" },
        { label: "RX-7", value: "rx-7" },
        { label: "RX-8", value: "rx-8" },
        { label: "Tribute", value: "tribute" },
        { label: "Xedos 6", value: "xedos 6" },
        { label: "Xedos 9", value: "xedos 9" },
    ],
    McLaren: [
        { label: "570S", value: "570s" },
        { label: "650S", value: "650s" },
        { label: "675LT", value: "675lt" },
        { label: "720s", value: "720s" },
        { label: "F1", value: "f1" },
        { label: "M6GT", value: "m6gt" },
        { label: "MP4-12C", value: "mp4-12c" },
        { label: "P1", value: "p1" },
        { label: "Senna", value: "senna" },
    ],
    "Mercedes-Benz": [
        { label: "A-класс", value: "a-class" },
        { label: "AMG", value: "amg" },
        { label: "B-класс", value: "b-class" },
        { label: "C-класс", value: "c-class" },
        { label: "CL-класс", value: "cl-class" },
        { label: "CLA-класс", value: "cla-class" },
        { label: "CLC-класс", value: "clc-class" },
        { label: "CLK-класс", value: "clk-class" },
        { label: "CLS-класс", value: "cls-class" },
        { label: "E-класс", value: "e-class" },
        { label: "G-класс", value: "g-class" },
        { label: "GL-класс", value: "gl-class" },
        { label: "GLA-класс", value: "gla-class" },
        { label: "GLC-класс", value: "glc-class" },
        { label: "GLE-класс", value: "gle-class" },
        { label: "GLK-класс", value: "glk-class" },
        { label: "GLS-класс", value: "gls-class" },
        { label: "ML-класс", value: "ml-class" },
        { label: "R-класс", value: "r-class" },
        { label: "S-класс", value: "s-class" },
        { label: "SL-класс", value: "sl-class" },
        { label: "SLC-класс", value: "slc-class" },
        { label: "SLK-класс", value: "slk-class" },
        { label: "V-класс", value: "v-class" },
        { label: "X-класс", value: "x-class" },
        { label: "Citan", value: "citan" },
        { label: "MB100", value: "mb100" },
        { label: "SLR", value: "slr" },
        { label: "SLS AMG", value: "sls amg" },
        { label: "Sprinter", value: "sprinter" },
        { label: "T1", value: "t1" },
        { label: "T2", value: "t2" },
        { label: "Unimog", value: "unimog" },
        { label: "Vaneo", value: "vaneo" },
        { label: "Vario", value: "vario" },
        { label: "Viano", value: "viano" },
        { label: "Vito", value: "vito" },
    ],
    Mercury: [
        { label: "Capri", value: "capri" },
        { label: "Colony Park", value: "colony park" },
        { label: "Cougar", value: "cougar" },
        { label: "Grand Marquis", value: "grand marquis" },
        { label: "Marauder", value: "marauder" },
        { label: "Mariner", value: "mariner" },
        { label: "Milan", value: "milan" },
        { label: "Montego", value: "montego" },
        { label: "Monterey", value: "monterey" },
        { label: "Mountaineer", value: "mountaineer" },
        { label: "Mystique", value: "mystique" },
        { label: "Sable", value: "sable" },
        { label: "Topaz", value: "topaz" },
        { label: "Tracer", value: "tracer" },
        { label: "Villager", value: "villager" },
    ],
    MG: [
        { label: "3", value: "3" },
        { label: "350", value: "350" },
        { label: "5", value: "5" },
        { label: "550", value: "550" },
        { label: "6", value: "6" },
        { label: "750", value: "750" },
        { label: "950", value: "950" },
        { label: "ZR", value: "zr" },
        { label: "ZS", value: "zs" },
        { label: "ZT", value: "zt" },
    ],
    Microcar: [
        { label: "Family Luxe", value: "family luxe" },
        { label: "Lyra", value: "lyra" },
        { label: "M.Go", value: "m.go" },
        { label: "MC1", value: "mc1" },
        { label: "NewStreet", value: "newstreet" },
        { label: "Pratic Luxe", value: "pratic luxe" },
        { label: "Virgo", value: "virgo" },
    ],
    Mini: [
        { label: "1000", value: "1000" },
        { label: "1300", value: "1300" },
        { label: "Clubman", value: "clubman" },
        { label: "Cooper", value: "cooper" },
        { label: "Cooper S", value: "cooper s" },
        { label: "Countryman", value: "countryman" },
        { label: "John Cooper Works", value: "john cooper works" },
        { label: "One", value: "one" },
        { label: "Paceman", value: "paceman" },
    ],
    Mitsubishi: [
        { label: "3000GT", value: "3000gt" },
        { label: "ASX", value: "asx" },
        { label: "Carisma", value: "carisma" },
        { label: "Colt", value: "colt" },
        { label: "Delica", value: "delica" },
        { label: "Diamante", value: "diamante" },
        { label: "Eclipse", value: "eclipse" },
        { label: "Eclipse Cross", value: "eclipse cross" },
        { label: "Endeavor", value: "endeavor" },
        { label: "Galant", value: "galant" },
        { label: "Grandis", value: "grandis" },
        { label: "i-MiEV", value: "i-miev" },
        { label: "L200", value: "l200" },
        { label: "L300", value: "l300" },
        { label: "L400", value: "l400" },
        { label: "Lancer", value: "lancer" },
        { label: "Lancer Evolution", value: "lancer evolution" },
        { label: "Legnum", value: "legnum" },
        { label: "Mirage", value: "mirage" },
        { label: "Montero", value: "montero" },
        { label: "Outlander", value: "outlander" },
        { label: "Pajero", value: "pajero" },
        { label: "Pajero Pinin", value: "pajero pinin" },
        { label: "Pajero Sport", value: "pajero sport" },
        { label: "Raider", value: "raider" },
        { label: "RVR", value: "rvr" },
        { label: "Sapporo", value: "sapporo" },
        { label: "Sigma", value: "sigma" },
        { label: "Space Gear", value: "space gear" },
        { label: "Space Runner", value: "space runner" },
        { label: "Space Star", value: "space star" },
        { label: "Space Wagon", value: "space wagon" },
    ],
    Morris: [
        { label: "1800", value: "1800" },
        { label: "2200", value: "2200" },
        { label: "Commercial J-type", value: "commercial j-type" },
        { label: "Commercial J2", value: "commercial j2" },
        { label: "Commercial J4", value: "commercial j4" },
        { label: "Cowley", value: "cowley" },
        { label: "Eight", value: "eight" },
        { label: "Isis", value: "isis" },
        { label: "Ital", value: "ital" },
        { label: "Major", value: "major" },
        { label: "Marina", value: "marina" },
        { label: "Marshal", value: "marshal" },
        { label: "Minor", value: "minor" },
        { label: "Nomad", value: "nomad" },
        { label: "Oxford", value: "oxford" },
        { label: "Six MS", value: "six ms" },
        { label: "Ten", value: "ten" },
    ],
    MPM: [{ label: "PS160", value: "ps160" }],
    Mudan: [{ label: "MD", value: "md" }],
    Nissan: [
        { label: "100NX", value: "100nx" },
        { label: "200SX", value: "200sx" },
        { label: "240SX", value: "240sx" },
        { label: "280ZX", value: "280zx" },
        { label: "300ZX", value: "300zx" },
        { label: "350Z", value: "350z" },
        { label: "370Z", value: "370z" },
        { label: "Almera", value: "almera" },
        { label: "Almera Tino", value: "almera tino" },
        { label: "Altima", value: "altima" },
        { label: "Armada", value: "armada" },
        { label: "Bluebird", value: "bluebird" },
        { label: "Cabstar", value: "cabstar" },
        { label: "Cedric", value: "cedric" },
        { label: "Cefiro", value: "cefiro" },
        { label: "Cherry", value: "cherry" },
        { label: "Cube", value: "cube" },
        { label: "Frontier", value: "frontier" },
        { label: "GT-R", value: "gt-r" },
        { label: "Interstar", value: "interstar" },
        { label: "Juke", value: "juke" },
        { label: "Krom", value: "krom" },
        { label: "L50", value: "l50" },
        { label: "Laurel", value: "laurel" },
        { label: "Leaf", value: "leaf" },
        { label: "Liberta Villa", value: "liberta villa" },
        { label: "Maxima", value: "maxima" },
        { label: "Micra", value: "micra" },
        { label: "Murano", value: "murano" },
        { label: "Navara", value: "navara" },
        { label: "Note", value: "note" },
        { label: "NP300 PICK UP", value: "np300 pick up" },
        { label: "NV S", value: "nv s" },
        { label: "NV200", value: "nv200" },
        { label: "Pathfinder", value: "pathfinder" },
        { label: "Patrol", value: "patrol" },
        { label: "Pixo", value: "pixo" },
        { label: "Prairie", value: "prairie" },
        { label: "Primastar", value: "primastar" },
        { label: "Primera", value: "primera" },
        { label: "Pulsar", value: "pulsar" },
        { label: "Qashqai", value: "qashqai" },
        { label: "Qashqai+2", value: "qashqai+2" },
        { label: "Quest", value: "quest" },
        { label: "Rogue", value: "rogue" },
        { label: "Sentra", value: "sentra" },
        { label: "Serena", value: "serena" },
        { label: "Silvia", value: "silvia" },
        { label: "Skyline", value: "skyline" },
        { label: "Skyline GT-R", value: "skyline gt-r" },
        { label: "Stanza", value: "stanza" },
        { label: "Sunny", value: "sunny" },
        { label: "Teana", value: "teana" },
        { label: "Terrano", value: "terrano" },
        { label: "Tiida", value: "tiida" },
        { label: "Tino", value: "tino" },
        { label: "Titan", value: "titan" },
        { label: "Trade", value: "trade" },
        { label: "Urvan", value: "urvan" },
        { label: "Vanette", value: "vanette" },
        { label: "Versa", value: "versa" },
        { label: "X-Trail", value: "x-trail" },
        { label: "Xterra", value: "xterra" },
    ],
    Nysa: [
        { label: "501", value: "501" },
        { label: "521", value: "521" },
        { label: "522", value: "522" },
    ],
    Oldsmobile: [
        { label: "98", value: "98" },
        { label: "Alero", value: "alero" },
        { label: "Aurora", value: "aurora" },
        { label: "Bravada", value: "bravada" },
        { label: "Custom Cruiser", value: "custom cruiser" },
        { label: "Cutlass", value: "cutlass" },
        { label: "Delta 88", value: "delta 88" },
        { label: "Firenza", value: "firenza" },
        { label: "Silhouette", value: "silhouette" },
        { label: "Supreme", value: "supreme" },
        { label: "Toronado", value: "toronado" },
    ],
    Oltcit: [
        { label: "Club", value: "club" },
        { label: "Special", value: "special" },
    ],
    Opel: [
        { label: "Adam", value: "adam" },
        { label: "Agila", value: "agila" },
        { label: "Ampera", value: "ampera" },
        { label: "Ampera-e", value: "ampera-e" },
        { label: "Antara", value: "antara" },
        { label: "Arena", value: "arena" },
        { label: "Ascona", value: "ascona" },
        { label: "Astra", value: "astra" },
        { label: "Bedford Blitz", value: "bedford blitz" },
        { label: "Calibra", value: "calibra" },
        { label: "Campo", value: "campo" },
        { label: "Cascada", value: "cascada" },
        { label: "Cavalier", value: "cavalier" },
        { label: "Combo", value: "combo" },
        { label: "Commodore", value: "commodore" },
        { label: "Corsa", value: "corsa" },
        { label: "Crossland X", value: "crossland x" },
        { label: "Diplomat", value: "diplomat" },
        { label: "Frontera", value: "frontera" },
        { label: "Grandland X", value: "grandland x" },
        { label: "GT", value: "gt" },
        { label: "Insignia", value: "insignia" },
        { label: "Kadett", value: "kadett" },
        { label: "Kapitan", value: "kapitan" },
        { label: "Manta", value: "manta" },
        { label: "Meriva", value: "meriva" },
        { label: "Mokka", value: "mokka" },
        { label: "Monterey", value: "monterey" },
        { label: "Monza", value: "monza" },
        { label: "Movano", value: "movano" },
        { label: "Nova", value: "nova" },
        { label: "Olympia", value: "olympia" },
        { label: "Omega", value: "omega" },
        { label: "Pick Up", value: "pick up" },
        { label: "Rekord", value: "rekord" },
        { label: "Senator", value: "senator" },
        { label: "Signum", value: "signum" },
        { label: "Sintra", value: "sintra" },
        { label: "Speedster", value: "speedster" },
        { label: "Tigra", value: "tigra" },
        { label: "Vectra", value: "vectra" },
        { label: "Vivaro", value: "vivaro" },
        { label: "Zafira", value: "zafira" },
    ],
    Peugeot: [
        { label: "1007", value: "1007" },
        { label: "104", value: "104" },
        { label: "106", value: "106" },
        { label: "107", value: "107" },
        { label: "108", value: "108" },
        { label: "2008", value: "2008" },
        { label: "204", value: "204" },
        { label: "205", value: "205" },
        { label: "20", value: "20" },
        { label: "207", value: "207" },
        { label: "208", value: "208" },
        { label: "3008", value: "3008" },
        { label: "301", value: "301" },
        { label: "304", value: "304" },
        { label: "305", value: "305" },
        { label: "306", value: "306" },
        { label: "307", value: "307" },
        { label: "308", value: "308" },
        { label: "309", value: "309" },
        { label: "4007", value: "4007" },
        { label: "4008", value: "4008" },
        { label: "404", value: "404" },
        { label: "405", value: "405" },
        { label: "406", value: "406" },
        { label: "407", value: "407" },
        { label: "408", value: "408" },
        { label: "5008", value: "5008" },
        { label: "504", value: "504" },
        { label: "505", value: "505" },
        { label: "508", value: "508" },
        { label: "604", value: "604" },
        { label: "605", value: "605" },
        { label: "607", value: "607" },
        { label: "805", value: "805" },
        { label: "806", value: "806" },
        { label: "807", value: "807" },
        { label: "Bipper", value: "bipper" },
        { label: "Bipper Tepee", value: "bipper tepee" },
        { label: "Boxer", value: "boxer" },
        { label: "Expert", value: "expert" },
        { label: "Expert Tepee", value: "expert tepee" },
        { label: "iOn", value: "ion" },
        { label: "J5", value: "j5" },
        { label: "J9", value: "j9" },
        { label: "Pars", value: "pars" },
        { label: "Partner", value: "partner" },
        { label: "Partner Tepee", value: "partner tepee" },
        { label: "RCZ", value: "rcz" },
        { label: "TePee", value: "tepee" },
        { label: "Traveller", value: "traveller" },
    ],
    Piaggio: [{ label: "Ape", value: "ape" }],
    Pinzgauer: [
        { label: "710K", value: "710k" },
        { label: "710M", value: "710m" },
        { label: "712K", value: "712k" },
        { label: "712M", value: "712m" },
        { label: "716", value: "716" },
    ],
    Plymouth: [
        { label: "Breeze", value: "breeze" },
        { label: "Grand Voyager", value: "grand voyager" },
        { label: "Horizon", value: "horizon" },
        { label: "Laser", value: "laser" },
        { label: "Neon", value: "neon" },
        { label: "Reliant", value: "reliant" },
        { label: "Sundance", value: "sundance" },
        { label: "Voyager", value: "voyager" },
    ],
    Pontiac: [
        { label: "6000", value: "6000" },
        { label: "Aztek", value: "aztek" },
        { label: "Bonneville", value: "bonneville" },
        { label: "Fiero", value: "fiero" },
        { label: "Firebird", value: "firebird" },
        { label: "G3", value: "g3" },
        { label: "G5", value: "g5" },
        { label: "G6", value: "g6" },
        { label: "G8", value: "g8" },
        { label: "Grand Am", value: "grand am" },
        { label: "Grand Prix", value: "grand prix" },
        { label: "GTO", value: "gto" },
        { label: "LeMans", value: "lemans" },
        { label: "Montana", value: "montana" },
        { label: "Solstice", value: "solstice" },
        { label: "Sunbird", value: "sunbird" },
        { label: "Sunfire", value: "sunfire" },
        { label: "T1000", value: "t1000" },
        { label: "Torrent", value: "torrent" },
        { label: "Trans Sport", value: "trans sport" },
        { label: "Vibe", value: "vibe" },
    ],
    Porsche: [
        { label: "718 Boxster", value: "718 boxster" },
        { label: "911", value: "911" },
        { label: "912", value: "912" },
        { label: "914", value: "914" },
        { label: "924", value: "924" },
        { label: "928", value: "928" },
        { label: "944", value: "944" },
        { label: "968", value: "968" },
        { label: "996", value: "996" },
        { label: "Boxster", value: "boxster" },
        { label: "Carrera GT", value: "carrera gt" },
        { label: "Cayenne", value: "cayenne" },
        { label: "Cayman", value: "cayman" },
        { label: "Macan", value: "macan" },
        { label: "Panamera", value: "panamera" },
    ],
    Proton: [
        { label: "300-Series", value: "300-series" },
        { label: "400-Series", value: "400-series" },
        { label: "Perdana", value: "perdana" },
        { label: "Persona", value: "persona" },
        { label: "Salon Airoback", value: "salon airoback" },
        { label: "Satria", value: "satria" },
        { label: "Waja", value: "waja" },
        { label: "Wira", value: "wira" },
    ],
    Ravon: [
        { label: "Gentra", value: "gentra" },
        { label: "R2", value: "r2" },
        { label: "R3 Nexia", value: "r3 nexia" },
        { label: "R4", value: "r4" },
    ],
    Renault: [
        { label: "11", value: "11" },
        { label: "14", value: "14" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "25", value: "25" },
        { label: "30", value: "30" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "9", value: "9" },
        { label: "Alpine", value: "alpine" },
        { label: "Arkana", value: "arkana" },
        { label: "Avantime", value: "avantime" },
        { label: "Captur", value: "captur" },
        { label: "Clio", value: "clio" },
        { label: "Coupe", value: "coupe" },
        { label: "Dokker", value: "dokker" },
        { label: "Duster", value: "duster" },
        { label: "Espace", value: "espace" },
        { label: "Express", value: "express" },
        { label: "Fluence", value: "fluence" },
        { label: "Fuego", value: "fuego" },
        { label: "Grand Espace", value: "grand espace" },
        { label: "Grand Modus", value: "grand modus" },
        { label: "Grand Scenic", value: "grand scenic" },
        { label: "Kadjar", value: "kadjar" },
        { label: "Kangoo", value: "kangoo" },
        { label: "Kaptur", value: "kaptur" },
        { label: "Koleos", value: "koleos" },
        { label: "Laguna", value: "laguna" },
        { label: "Latitude", value: "latitude" },
        { label: "Lodgy", value: "lodgy" },
        { label: "Logan", value: "logan" },
        { label: "Manager", value: "manager" },
        { label: "Mascott", value: "mascott" },
        { label: "Master", value: "master" },
        { label: "Maxity", value: "maxity" },
        { label: "Megane", value: "megane" },
        { label: "Megane Scenic", value: "megane scenic" },
        { label: "Messenger", value: "messenger" },
        { label: "Modus", value: "modus" },
        { label: "P1400", value: "p1400" },
        { label: "Rapid", value: "rapid" },
        { label: "Safrane", value: "safrane" },
        { label: "Sandero", value: "sandero" },
        { label: "Scenic", value: "scenic" },
        { label: "Spider", value: "spider" },
        { label: "Symbol", value: "symbol" },
        { label: "Talisman", value: "talisman" },
        { label: "Thalia", value: "thalia" },
        { label: "Trafic", value: "trafic" },
        { label: "Twingo", value: "twingo" },
        { label: "Twizy", value: "twizy" },
        { label: "Vel Satis", value: "vel satis" },
        { label: "Wind", value: "wind" },
        { label: "Zoe", value: "zoe" },
    ],
    Roewe: [
        { label: "350", value: "350" },
        { label: "360", value: "360" },
        { label: "550", value: "550" },
        { label: "750", value: "750" },
        { label: "850", value: "850" },
        { label: "950", value: "950" },
        { label: "E50", value: "e50" },
        { label: "i5", value: "i5" },
        { label: "i6", value: "i6" },
        { label: "RX3", value: "rx3" },
        { label: "RX5", value: "rx5" },
        { label: "RX7", value: "rx7" },
        { label: "RX8", value: "rx8" },
        { label: "W5", value: "w5" },
    ],
    "Rolls-Royce": [
        { label: "Ghost", value: "ghost" },
        { label: "Phantom", value: "phantom" },
        { label: "Silver Spirit", value: "silver spirit" },
    ],
    Rover: [
        { label: "100-Series", value: "100-series" },
        { label: "111", value: "111" },
        { label: "200-Series", value: "200-series" },
        { label: "25", value: "25" },
        { label: "3500", value: "3500" },
        { label: "400-Series", value: "400-series" },
        { label: "45", value: "45" },
        { label: "600-Series", value: "600-series" },
        { label: "75", value: "75" },
        { label: "800-Series", value: "800-series" },
        { label: "Austin", value: "austin" },
        { label: "Clubman", value: "clubman" },
        { label: "Maestro", value: "maestro" },
        { label: "Metro", value: "metro" },
        { label: "MGF", value: "mgf" },
        { label: "Mini", value: "mini" },
        { label: "Montego", value: "montego" },
        { label: "Ostin", value: "ostin" },
        { label: "Streetwise", value: "streetwise" },
        { label: "Vitesse", value: "vitesse" },
    ],
    Saab: [
        { label: "9 - 3", value: "9 - 3" },
        { label: "9 - 5", value: "9 - 5" },
        { label: "9 - 7X", value: "9 - 7x" },
        { label: "9-2X", value: "9-2x" },
        { label: "900", value: "900" },
        { label: "9000", value: "9000" },
        { label: "99", value: "99" },
    ],
    Saipa: [
        { label: "111", value: "111" },
        { label: "131", value: "131" },
        { label: "132", value: "132" },
        { label: "141", value: "141" },
        { label: "151", value: "151" },
        { label: "2", value: "2" },
        { label: "Cerato", value: "cerato" },
        { label: "CS35", value: "cs35" },
        { label: "Eado", value: "eado" },
        { label: "H230", value: "h230" },
        { label: "H320", value: "h320" },
        { label: "H330", value: "h330" },
        { label: "Kolius", value: "kolius" },
        { label: "Padra", value: "padra" },
        { label: "S300", value: "s300" },
        { label: "Saina", value: "saina" },
        { label: "Tiba", value: "tiba" },
        { label: "Tondar", value: "tondar" },
        { label: "V5", value: "v5" },
    ],
    Santana: [{ label: "Anibal", value: "anibal" }],
    Saturn: [
        { label: "Astra", value: "astra" },
        { label: "Aura", value: "aura" },
        { label: "Ion", value: "ion" },
        { label: "L", value: "l" },
        { label: "LS", value: "ls" },
        { label: "LW", value: "lw" },
        { label: "Outlook", value: "outlook" },
        { label: "SC", value: "sc" },
        { label: "Sky", value: "sky" },
        { label: "SL", value: "sl" },
        { label: "SW", value: "sw" },
        { label: "VUE", value: "vue" },
    ],
    Scion: [
        { label: "FR-S", value: "fr-s" },
        { label: "iA", value: "ia" },
        { label: "iM", value: "im" },
        { label: "tC", value: "tc" },
        { label: "xA", value: "xa" },
        { label: "xB", value: "xb" },
        { label: "xD", value: "xd" },
    ],
    Seat: [
        { label: "Alhambra", value: "alhambra" },
        { label: "Altea", value: "altea" },
        { label: "Arosa", value: "arosa" },
        { label: "Cordoba", value: "cordoba" },
        { label: "Exeo", value: "exeo" },
        { label: "Ibiza", value: "ibiza" },
        { label: "Inca", value: "inca" },
        { label: "Leon", value: "leon" },
        { label: "Malaga", value: "malaga" },
        { label: "Marbella", value: "marbella" },
        { label: "Mii", value: "mii" },
        { label: "Terra", value: "terra" },
        { label: "Toledo", value: "toledo" },
    ],
    Shuanghuan: [
        { label: "CEO", value: "ceo" },
        { label: "Laibao", value: "laibao" },
        { label: "Noble", value: "noble" },
    ],
    "Simca-Chrysler": [
        { label: "1307", value: "1307" },
        { label: "1308", value: "1308" },
        { label: "1309", value: "1309" },
    ],
    Skoda: [
        { label: "105", value: "105" },
        { label: "120", value: "120" },
        { label: "1200", value: "1200" },
        { label: "1202", value: "1202" },
        { label: "1203", value: "1203" },
        { label: "130", value: "130" },
        { label: "Citigo", value: "citigo" },
        { label: "Fabia", value: "fabia" },
        { label: "Fabia RS", value: "fabia rs" },
        { label: "Favorit", value: "favorit" },
        { label: "Felicia", value: "felicia" },
        { label: "Forman", value: "forman" },
        { label: "Karoq", value: "karoq" },
        { label: "Kodiaq", value: "kodiaq" },
        { label: "Octavia", value: "octavia" },
        { label: "Octavia RS", value: "octavia rs" },
        { label: "Praktik", value: "praktik" },
        { label: "Rapid", value: "rapid" },
        { label: "Roomster", value: "roomster" },
        { label: "Superb", value: "superb" },
        { label: "Yeti", value: "yeti" },
    ],
    Smart: [
        { label: "City Coupe", value: "city coupe" },
        { label: "Crossblade", value: "crossblade" },
        { label: "Forfour", value: "forfour" },
        { label: "Fortwo", value: "fortwo" },
        { label: "Roadster", value: "roadster" },
    ],
    Spyker: [{ label: "C8", value: "c8" }],
    SsangYong: [
        { label: "Actyon", value: "actyon" },
        { label: "Chairman", value: "chairman" },
        { label: "Family", value: "family" },
        { label: "Istana", value: "istana" },
        { label: "Kallista", value: "kallista" },
        { label: "Korando", value: "korando" },
        { label: "Kyron", value: "kyron" },
        { label: "Musso", value: "musso" },
        { label: "Rexton", value: "rexton" },
        { label: "Rodius", value: "rodius" },
        { label: "Stavic", value: "stavic" },
    ],
    Steyr: [
        { label: "220", value: "220" },
        { label: "50", value: "50" },
        { label: "55", value: "55" },
    ],
    Subaru: [
        { label: "Baja", value: "baja" },
        { label: "BRZ", value: "brz" },
        { label: "Forester", value: "forester" },
        { label: "Impreza", value: "impreza" },
        { label: "Justy", value: "justy" },
        { label: "Legacy", value: "legacy" },
        { label: "Leone", value: "leone" },
        { label: "Libero", value: "libero" },
        { label: "Outback", value: "outback" },
        { label: "SVX", value: "svx" },
        { label: "Trezia", value: "trezia" },
        { label: "Tribeca", value: "tribeca" },
        { label: "Vivio", value: "vivio" },
        { label: "WRX", value: "wrx" },
        { label: "XV", value: "xv" },
    ],
    Suzuki: [
        { label: "Aerio", value: "aerio" },
        { label: "Alto", value: "alto" },
        { label: "Baleno", value: "baleno" },
        { label: "Carry", value: "carry" },
        { label: "Equator", value: "equator" },
        { label: "Forenza", value: "forenza" },
        { label: "Grand Vitara", value: "grand vitara" },
        { label: "Ignis", value: "ignis" },
        { label: "Jimny", value: "jimny" },
        { label: "Kizashi", value: "kizashi" },
        { label: "Liana", value: "liana" },
        { label: "Reno", value: "reno" },
        { label: "Samurai", value: "samurai" },
        { label: "Splash", value: "splash" },
        { label: "Super Carry", value: "super carry" },
        { label: "Swift", value: "swift" },
        { label: "SX4", value: "sx4" },
        { label: "SX4 S-Cross", value: "sx4 s-cross" },
        { label: "Verona", value: "verona" },
        { label: "Vitara", value: "vitara" },
        { label: "Wagon R", value: "wagon r" },
        { label: "X-90", value: "x-90" },
        { label: "XL7", value: "xl7" },
    ],
    Syrena: [
        { label: "100", value: "100" },
        { label: "101", value: "101" },
        { label: "102", value: "102" },
        { label: "103", value: "103" },
        { label: "104", value: "104" },
        { label: "105", value: "105" },
    ],
    Talbot: [
        { label: "Horizon", value: "horizon" },
        { label: "Solara", value: "solara" },
    ],
    Tata: [
        { label: "Aria", value: "aria" },
        { label: "Indica", value: "indica" },
        { label: "Indigo", value: "indigo" },
        { label: "Manza", value: "manza" },
        { label: "Nano", value: "nano" },
        { label: "Safari Dicor", value: "safari dicor" },
        { label: "Safari Storme", value: "safari storme" },
        { label: "Sumo Gold", value: "sumo gold" },
        { label: "Sumo Grande", value: "sumo grande" },
        { label: "Telcoline", value: "telcoline" },
        { label: "Venture", value: "venture" },
        { label: "Vista", value: "vista" },
        { label: "Xenon XT", value: "xenon xt" },
    ],
    Tatra: [
        { label: "11", value: "11" },
        { label: "700", value: "700" },
        { label: "T600", value: "t600" },
        { label: "T603", value: "t603" },
        { label: "T613", value: "t613" },
        { label: "T97", value: "t97" },
    ],
    Tesla: [
        { label: "Model 3", value: "model 3" },
        { label: "Model S", value: "model s" },
        { label: "Model X", value: "model x" },
    ],
    Tianma: [
        { label: "Century", value: "century" },
        { label: "Chenglong", value: "chenglong" },
        { label: "Dragon", value: "dragon" },
        { label: "Hero", value: "hero" },
    ],
    Toyota: [
        { label: "4Runner", value: "4runner" },
        { label: "Alphard", value: "alphard" },
        { label: "Altezza", value: "altezza" },
        { label: "Auris", value: "auris" },
        { label: "Avalon", value: "avalon" },
        { label: "Avensis", value: "avensis" },
        { label: "Avensis Verso", value: "avensis verso" },
        { label: "Aygo", value: "aygo" },
        { label: "BB", value: "bb" },
        { label: "C-HR", value: "c-hr" },
        { label: "Camry", value: "camry" },
        { label: "Carina E", value: "carina e" },
        { label: "Carina II", value: "carina ii" },
        { label: "Celica", value: "celica" },
        { label: "Celsior", value: "celsior" },
        { label: "Chaser", value: "chaser" },
        { label: "Corolla", value: "corolla" },
        { label: "Corolla Verso", value: "corolla verso" },
        { label: "Corona", value: "corona" },
        { label: "Cressida", value: "cressida" },
        { label: "Cresta", value: "cresta" },
        { label: "Crown", value: "crown" },
        { label: "Echo", value: "echo" },
        { label: "FJ Cruiser", value: "fj cruiser" },
        { label: "Fortuner", value: "fortuner" },
        { label: "GT 86", value: "gt 86" },
        { label: "HiAce", value: "hiace" },
        { label: "Highlander", value: "highlander" },
        { label: "Hilux", value: "hilux" },
        { label: "IQ", value: "iq" },
        { label: "Land Cruiser", value: "land cruiser" },
        { label: "Land Cruiser Prado", value: "land cruiser prado" },
        { label: "LiteAce", value: "liteace" },
        { label: "Mark", value: "mark" },
        { label: "Matrix", value: "matrix" },
        { label: "Model F", value: "model f" },
        { label: "MR2", value: "mr2" },
        { label: "Paseo", value: "paseo" },
        { label: "Picnic", value: "picnic" },
        { label: "Previa", value: "previa" },
        { label: "Prius", value: "prius" },
        { label: "ProAce", value: "proace" },
        { label: "RAV4", value: "rav4" },
        { label: "Sequoia", value: "sequoia" },
        { label: "Sienna", value: "sienna" },
        { label: "Soarer", value: "soarer" },
        { label: "Solara", value: "solara" },
        { label: "Sprinter", value: "sprinter" },
        { label: "Starlet", value: "starlet" },
        { label: "Supra", value: "supra" },
        { label: "Tacoma", value: "tacoma" },
        { label: "Tercel", value: "tercel" },
        { label: "TownAce", value: "townace" },
        { label: "Tundra", value: "tundra" },
        { label: "Urban Cruiser", value: "urban cruiser" },
        { label: "Venza", value: "venza" },
        { label: "Verso", value: "verso" },
        { label: "Vista", value: "vista" },
        { label: "Yaris", value: "yaris" },
    ],
    Trabant: [
        { label: "1.1", value: "1.1" },
        { label: "601", value: "601" },
        { label: "P50", value: "p50" },
        { label: "P60", value: "p60" },
        { label: "P70", value: "p70" },
    ],
    Triumph: [
        { label: "Spitfire", value: "spitfire" },
        { label: "TR7", value: "tr7" },
    ],
    Vauxhall: [
        { label: "Adam", value: "adam" },
        { label: "Ampera", value: "ampera" },
        { label: "Antara", value: "antara" },
        { label: "Astra", value: "astra" },
        { label: "Cascada", value: "cascada" },
        { label: "Combo Tour", value: "combo tour" },
        { label: "Corsa", value: "corsa" },
        { label: "Insignia", value: "insignia" },
        { label: "Meriva", value: "meriva" },
        { label: "Mokka", value: "mokka" },
        { label: "Viva", value: "viva" },
        { label: "Vivaro", value: "vivaro" },
        { label: "VXR8", value: "vxr8" },
        { label: "Zafira Tourer", value: "zafira tourer" },
    ],
    Volkswagen: [
        { label: "181", value: "181" },
        { label: "Amarok", value: "amarok" },
        { label: "Arteon", value: "arteon" },
        { label: "Atlas", value: "atlas" },
        { label: "Beetle", value: "beetle" },
        { label: "Bora", value: "bora" },
        { label: "Buggy", value: "buggy" },
        { label: "Caddy", value: "caddy" },
        { label: "Corrado", value: "corrado" },
        { label: "Crafter", value: "crafter" },
        { label: "Cross Polo", value: "cross polo" },
        { label: "Derby", value: "derby" },
        { label: "Eos", value: "eos" },
        { label: "Fox", value: "fox" },
        { label: "Golf", value: "golf" },
        { label: "Golf GTI", value: "golf gti" },
        { label: "Golf Plus", value: "golf plus" },
        { label: "Golf R", value: "golf r" },
        { label: "Golf R32", value: "golf r32" },
        { label: "Golf Sportsvan", value: "golf sportsvan" },
        { label: "ID.3", value: "id.3" },
        { label: "Iltis", value: "iltis" },
        { label: "Jetta", value: "jetta" },
        { label: "Kafer", value: "kafer" },
        { label: "Karmann Ghia", value: "karmann ghia" },
        { label: "LT", value: "lt" },
        { label: "Lupo", value: "lupo" },
        { label: "New Beetle", value: "new beetle" },
        { label: "Passat", value: "passat" },
        { label: "Passat CC", value: "passat cc" },
        { label: "Phaeton", value: "phaeton" },
        { label: "Pointer", value: "pointer" },
        { label: "Polo", value: "polo" },
        { label: "Polo Sedan", value: "polo sedan" },
        { label: "Rabbit", value: "rabbit" },
        { label: "Routan", value: "routan" },
        { label: "Santana", value: "santana" },
        { label: "Scirocco", value: "scirocco" },
        { label: "Sharan", value: "sharan" },
        { label: "T-Cross", value: "t-cross" },
        { label: "T-Roc", value: "t-roc" },
        { label: "T1", value: "t1" },
        { label: "T2", value: "t2" },
        { label: "T3", value: "t3" },
        { label: "T3 Caravelle", value: "t3 caravelle" },
        { label: "T3 Multivan", value: "t3 multivan" },
        { label: "T4", value: "t4" },
        { label: "T4 Caravelle", value: "t4 caravelle" },
        { label: "T4 Multivan", value: "t4 multivan" },
        { label: "T5", value: "t5" },
        { label: "T5 Caravelle", value: "t5 caravelle" },
        { label: "T5 Multivan", value: "t5 multivan" },
        { label: "T5 Shuttle", value: "t5 shuttle" },
        { label: "T6", value: "t6" },
        { label: "T6 Caravelle", value: "t6 caravelle" },
        { label: "T6 Multivan", value: "t6 multivan" },
        { label: "Taro", value: "taro" },
        { label: "Teramont", value: "teramont" },
        { label: "Tharu", value: "tharu" },
        { label: "Tiguan", value: "tiguan" },
        { label: "Touareg", value: "touareg" },
        { label: "Touran", value: "touran" },
        { label: "Up", value: "up" },
        { label: "Vento", value: "vento" },
    ],
    Volvo: [
        { label: "142", value: "142" },
        { label: "240", value: "240" },
        { label: "242", value: "242" },
        { label: "244", value: "244" },
        { label: "245", value: "245" },
        { label: "262", value: "262" },
        { label: "264", value: "264" },
        { label: "340", value: "340" },
        { label: "343", value: "343" },
        { label: "36", value: "36" },
        { label: "48", value: "48" },
        { label: "744", value: "744" },
        { label: "740" },
        { label: "745", value: "745" },
        { label: "760", value: "760" },
        { label: "78", value: "78" },
        { label: "855", value: "855" },
        { label: "940", value: "940" },
        { label: "944", value: "944" },
        { label: "945", value: "945" },
        { label: "960", value: "960" },
        { label: "965", value: "965" },
        { label: "Amazon", value: "amazon" },
        { label: "C30", value: "c30" },
        { label: "C70", value: "c70" },
        { label: "Polar", value: "polar" },
        { label: "S40", value: "s40" },
        { label: "S60", value: "s60" },
        { label: "S70", value: "s70" },
        { label: "S80", value: "s80" },
        { label: "S90", value: "s90" },
        { label: "V40", value: "v40" },
        { label: "V50", value: "v50" },
        { label: "V60", value: "v60" },
        { label: "V70", value: "v70" },
        { label: "V90", value: "v90" },
        { label: "XC40", value: "xc40" },
        { label: "XC60", value: "xc60" },
        { label: "XC70", value: "xc70" },
        { label: "XC90", value: "xc90" },
    ],
    Vortex: [
        { label: "Corda", value: "corda" },
        { label: "Estina", value: "estina" },
        { label: "Tingo", value: "tingo" },
    ],
    Wartburg: [
        { label: "353", value: "353" },
        { label: "Tourist", value: "tourist" },
    ],
    Xinkai: [
        { label: "Pickup X3", value: "pickup x3" },
        { label: "SRV X3", value: "srv x3" },
        { label: "SUV X3", value: "suv x3" },
    ],
    Zastava: [
        { label: "10", value: "10" },
        { label: "101", value: "101" },
        { label: "Cabrio", value: "cabrio" },
        { label: "Florida", value: "florida" },
        { label: "Koral", value: "koral" },
        { label: "Skala", value: "skala" },
        { label: "Yugo", value: "yugo" },
    ],
    Zotye: [
        { label: "2008", value: "2008" },
        { label: "Coupa", value: "coupa" },
        { label: "M300", value: "m300" },
        { label: "T600", value: "t600" },
        { label: "V10", value: "v10" },
        { label: "Z200", value: "z200" },
        { label: "Z300", value: "z300" },
    ],
    Zuk: [
        { label: "A05", value: "a05" },
        { label: "A06", value: "a06" },
        { label: "AH", value: "ah" },
    ],
    ZX: [
        { label: "Admiral", value: "admiral" },
        { label: "Chanling", value: "chanling" },
        { label: "Grand Tiger", value: "grand tiger" },
        { label: "Landmark", value: "landmark" },
    ],
    ВАЗ: [
        { label: "1111", value: "1111" },
        { label: "1119", value: "1119" },
        { label: "2101", value: "2101" },
        { label: "21011", value: "21011" },
        { label: "21013", value: "21013" },
        { label: "2102", value: "2102" },
        { label: "2103", value: "2103" },
        { label: "2104", value: "2104" },
        { label: "2105", value: "2105" },
        { label: "2106", value: "2106" },
        { label: "2107", value: "2107" },
        { label: "2108", value: "2108" },
        { label: "2109", value: "2109" },
        { label: "21099", value: "21099" },
        { label: "2110", value: "2110" },
        { label: "2111", value: "2111" },
        { label: "2112", value: "2112" },
        { label: "2113", value: "2113" },
        { label: "2114", value: "2114" },
        { label: "2115", value: "2115" },
        { label: "2120", value: "2120" },
        { label: "2121", value: "2121" },
        { label: "21213", value: "21213" },
        { label: "21214", value: "21214" },
        { label: "21215", value: "21215" },
        { label: "21218", value: "21218" },
        { label: "2123", value: "2123" },
        { label: "2129", value: "2129" },
        { label: "2130", value: "2130" },
        { label: "2131", value: "2131" },
        { label: "2310", value: "2310" },
        { label: "2328", value: "2328" },
        { label: "2329", value: "2329" },
        { label: "Granta", value: "granta" },
        { label: "Kalina", value: "kalina" },
        { label: "Largus", value: "largus" },
        { label: "Priora", value: "priora" },
        { label: "Vesta", value: "vesta" },
        { label: "XRAY", value: "xray" },
        { label: "ВИС", value: "вис" },
    ],
    ГАЗ: [
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "2217", value: "2217" },
        { label: "22171", value: "22171" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "2401", value: "2401" },
        { label: "2402", value: "2402" },
        { label: "2410", value: "2410" },
        { label: "2705", value: "2705" },
        { label: "2752", value: "2752" },
        { label: "2757", value: "2757" },
        { label: "2775", value: "2775" },
        { label: "3102", value: "3102" },
        { label: "310210", value: "310210" },
        { label: "31022", value: "31022" },
        { label: "310221", value: "310221" },
        { label: "31029", value: "31029" },
        { label: "3110", value: "3110" },
        { label: "31105", value: "31105" },
        { label: "3111", value: "3111" },
        { label: "3221", value: "3221" },
        { label: "322132", value: "322132" },
        { label: "322133", value: "322133" },
        { label: "3234", value: "3234" },
        { label: "3285", value: "3285" },
        { label: "3302", value: "3302" },
        { label: "3705", value: "3705" },
        { label: "37170", value: "37170" },
        { label: "67", value: "67" },
        { label: "69", value: "69" },
        { label: "Siber", value: "siber" },
        { label: "А21", value: "а21" },
        { label: "А22", value: "а22" },
        { label: "Луидор", value: "луидор" },
        { label: "М20", value: "м20" },
        { label: "Семар", value: "семар" },
        { label: "Соболь", value: "соболь" },
    ],
    ЕрАЗ: [
        { label: "3730", value: "3730" },
        { label: "762", value: "762" },
    ],
    ЗАЗ: [
        { label: "1103", value: "1103" },
        { label: "68", value: "68" },
        { label: "965", value: "965" },
        { label: "966", value: "966" },
        { label: "968а", value: "968а" },
        { label: "968м", value: "968м" },
        { label: "Chance", value: "chance" },
        { label: "Forza", value: "forza" },
        { label: "Lanos", value: "lanos" },
        { label: "Sens", value: "sens" },
        { label: "Vida", value: "vida" },
        { label: "Дана", value: "дана" },
        { label: "МД", value: "мд" },
        { label: "Таврия", value: "таврия" },
    ],
    ЗИС: [
        { label: "101", value: "101" },
        { label: "101А", value: "101а" },
        { label: "101С", value: "101с" },
        { label: "102", value: "102" },
        { label: "102А", value: "102а" },
        { label: "110", value: "110" },
        { label: "110А", value: "110а" },
        { label: "110Б", value: "110б" },
        { label: "110П", value: "110п" },
        { label: "115", value: "115" },
        { label: "Спорт", value: "спорт" },
    ],
    ИЖ: [
        { label: "2125", value: "2125" },
        { label: "2126", value: "2126" },
        { label: "2715", value: "2715" },
        { label: "2717", value: "2717" },
    ],
    ЛуАЗ: [
        { label: "967M", value: "967m" },
        { label: "969", value: "969" },
    ],
    Москвич: [
        { label: "2125", value: "2125" },
        { label: "2136 Kombi", value: "2136 kombi" },
        { label: "2137 Kombi", value: "2137 kombi" },
        { label: "2138", value: "2138" },
        { label: "2140", value: "2140" },
        { label: "2141", value: "2141" },
        { label: "2149", value: "2149" },
        { label: "400", value: "400" },
        { label: "401", value: "401" },
        { label: "402", value: "402" },
        { label: "403", value: "403" },
        { label: "406", value: "406" },
        { label: "407", value: "407" },
        { label: "408", value: "408" },
        { label: "410", value: "410" },
        { label: "412", value: "412" },
        { label: "423 Kombi", value: "423 kombi" },
        { label: "426", value: "426" },
        { label: "427", value: "427" },
        { label: "434", value: "434" },
        { label: "Князь Владимир", value: "князь владимир" },
        { label: "Святогор", value: "святогор" },
        { label: "Юрий Долгорукий", value: "юрий долгорукий" },
    ],
    РАФ: [{ label: "2203", value: "2203" }],
    СеАЗ: [
        { label: "1111", value: "1111" },
        { label: "11113", value: "11113" },
    ],
    СМЗ: [
        { label: "C-3А", value: "c-3а" },
        { label: "C-3АБ", value: "c-3аб" },
        { label: "С-3АМ", value: "с-3ам" },
        { label: "С-3Д", value: "с-3д" },
    ],
    ТагАЗ: [
        { label: "Aquila", value: "aquila" },
        { label: "Tager", value: "tager" },
        { label: "Vega C100", value: "vega c100" },
    ],
    УАЗ: [
        { label: "2206", value: "2206" },
        { label: "23602", value: "23602" },
        { label: "23632", value: "23632" },
        { label: "3151", value: "3151" },
        { label: "31512", value: "31512" },
        { label: "31514", value: "31514" },
        { label: "3153", value: "3153" },
        { label: "3160", value: "3160" },
        { label: "3303", value: "3303" },
        { label: "3741", value: "3741" },
        { label: "39099", value: "39099" },
        { label: "3962", value: "3962" },
        { label: "451", value: "451" },
        { label: "452", value: "452" },
        { label: "469", value: "469" },
        { label: "69A", value: "69a" },
        { label: "Hunter", value: "hunter" },
        { label: "Patriot", value: "patriot" },
    ],
};
