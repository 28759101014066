import React, { useState } from 'react';
import {Modal as Window} from "antd";
import {Formik} from "formik";
import ModalForm from "./ModalForm";
import api from "../../utils/api";
import styles from "./Modal.module.css";
import {FormError} from "../../utils/formError";
import {getURI} from "../../utils/getURI";
import axios from "axios";


const Modal = ({ isModalVisible, handleCancel, setOpenAdmin }) => {
  const [isCompleted, setCompleted] = useState(false)

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    if (values.phone.replace(/\D/g, '').length < 12) {
      setErrors({ phone: 'Телефон указан не верно' })
      setSubmitting(false)

      return
    }

    try {
      const uri = await getURI(values)
      const data = await axios.post(uri)

      if (data.status === 200) {
        setCompleted(true)
      }
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <Window
      title={
        isCompleted ?
          'Ваши контакты отправлены!' :
          "Хотите узнать стоимость автомобиля?"
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      closeIcon={<span/>}
    >
      <p className={styles.desc}>
        {!isCompleted ? <> Оставьте контактные данные <br/> и мы позвоним Вам в течение нескольких минут.</> :
        <>Ваши контактные данные были отправлены в адрес нашей мониторинговой системы. <br/>
        Мы свяжемся с Вами как можно раньше!</>}
      </p>
      {!isCompleted && <Formik
        enableReinitialize
        initialValues={{ name: '', phone: '' }}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return <ModalForm {...props} />;
        }}
      </Formik>}
    </Window>
  )
}

export default Modal;
