import React, {useEffect, useState} from "react"
import axios from "axios"
import { Formik } from "formik"
import classnames from "classnames"
import MainSectionFormContent from "./MainSectionFormContent"
import api from "../../../utils/api"

import styles from "./MainSectionForm.module.scss"
import {FormError} from "../../../utils/formError"
import {getURI} from "../../../utils/getURI"
import CompletedScreen from "../../CompletedScreen"

const template = {mark: '', model: '', year: '', price: 100, name: '', phone: ''}

const MainSectionForm = ({ complete, handleComplete }) => {
  const [init, setInit] = useState(template)

  useEffect(() => {
    const storage = localStorage.getItem('firstForm')
    setInit(storage ? JSON.parse(storage) : template)
  }, []);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {

    // Проверка всех полей на пустые значения
    const errors = {};

    if (!values.mark) {
      errors.mark = 'Укажите марку авто';
    }

    if (!values.model) {
      errors.model = 'Укажите модель авто';
    }

    if (!values.year) {
      errors.year = 'Укажите год авто';
    }

    if (!values.price) {
      errors.price = 'Укажите цену авто';
    }

    if (values.phone.replace(/\D/g, '').length < 12) {
      errors.phone = 'Телефон указан не верно';
    }

    // Если есть ошибки, установить их и прекратить выполнение
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);

      return
    }

    try {
      const uri = await getURI(values)
      const data = await axios.post(uri)

      if (data.status === 200) {
        handleComplete()
        localStorage.removeItem('firstForm')
      }
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <div className={styles.formWrapper}>
      <div className={classnames(styles.form, complete && styles.formCompleted)}>
        <p className="section-title">Оценка стоимости авто <span>за 5 минут</span></p>
        <div className={styles.formWrapper}>
          <p className={styles.formDesc}>
            Это поможет нам быстрее <br/> сделать вам предложение!
          </p>
          <Formik
            enableReinitialize
            initialValues={init}
            onSubmit={handleSubmit}
          >
            {(props) => {
              return <MainSectionFormContent {...props} />;
            }}
          </Formik>
        </div>
      </div>
      <div className={styles.completedScreenWrapper}>
        <CompletedScreen complete={complete} />
      </div>
    </div>
  );
};

export default MainSectionForm;
