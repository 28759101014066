import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import api from "../../utils/api";
import RatingAutoFormContent from "./RatingAutoFormContent";
import {FormError} from "../../utils/formError";

import styles from "./RatingAutoForm.module.css";
import CompletedScreen from "../CompletedScreen";
import {getURI} from "../../utils/getURI";
import axios from "axios";

const template = {
  mark: '',
  model: '',
  year: '',
  price: 100,
  phone: '',
  name: ''
}

const RatingAutoForm = ({ complete, handleComplete, mark }) => {
  const [init, setInit] = useState(template)

  useEffect(() => {
    const storage = localStorage.getItem('firstForm')
    setInit(storage ? JSON.parse(storage) : template)
  }, []);

  const handleSubmit = async (values, {setSubmitting, setErrors}) => {

    // Проверка всех полей на пустые значения
    const errors = {};

    if (!values.mark) {
      errors.mark = 'Укажите марку авто';
    }

    if (!values.model) {
      errors.model = 'Укажите модель авто';
    }

    if (!values.year) {
      errors.year = 'Укажите год авто';
    }

    if (!values.price) {
      errors.price = 'Укажите цену авто';
    }

    if (values.phone.replace(/\D/g, '').length < 12) {
      errors.phone = 'Телефон указан не верно';
    }

    // Если есть ошибки, установить их и прекратить выполнение
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setSubmitting(false);

      return
    }

    try {
      const uri = await getURI(values)
      const data = await axios.post(uri)

      if (data.status === 200) {
        handleComplete()
        localStorage.removeItem('firstForm')
      }
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <div id='rating-form' className={styles.form}>
      <Formik
        enableReinitialize
        initialValues={init}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return <RatingAutoFormContent {...props} mark={mark} />;
        }}
      </Formik>
      <CompletedScreen complete={complete}/>
    </div>
  );
};

export default RatingAutoForm;
